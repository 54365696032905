import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { actionCreators as merchantActionCreators } from "src/actions/merchant";
import { actionCreators } from "src/actions/product";
import { productService } from "src/services";
import { v4 as uuidv4 } from 'uuid';

import { code } from './statusCode';

// Ant design
import { Layout, Row, Col, Card, Form, Divider,
  Select, Input, Button, Radio, Typography,
  message, Table, notification, Spin } from "antd";
import {
  PlusOutlined,
  MinusOutlined
} from '@ant-design/icons';

import UploadImg from 'src/components/UploadImg';
import TextEditor from 'src/components/Editor';

// 圖片 url 轉圖檔格式
const toDataURL = async (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};

// 圖檔格式轉 binary
const convertBase64ToBlob = (base64Image) => {
  if (base64Image) {
    // Split into two parts
    const parts = base64Image.split(';base64,');
    // Hold the content type
    const imageType = parts[0].split(':')[1];
    // Decode Base64 string
    const decodedData = window.atob(parts[1]);
    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);
    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: imageType });
  }
};

const ProductInfo = () => {
  const [form] = Form.useForm();
  const [searchParams]= useSearchParams();
  const [prodNo] = useState(searchParams.get('no'));
  const [prodCloneNo] = useState(searchParams.get('cloneNo'));
  const [typeItemOptions, setTypeItemOptions] = useState([]);
  const [calcType, setCalcType] = useState('');
  const [calcTypeOpts, setCalcTypeOpts] = useState([]);
  const [prodSpecData, setProdSpecData] = useState([
    { id: uuidv4(), prodSpecName: '', prodInventory: 1 }
  ]);
  const [prodDetailDesString, setProdDetailDesString] = useState('');
  const [prodPhotoData, setProdPhotoData] = useState([]);
  const [prodDesPhotoData, setProdDesPhotoData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [calcLoading, setCalcLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { Option } = Select;
  const { Text } = Typography;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 取出 store 中的 state
  const merAcctId = useSelector(state => state.merchant.merAcctId);
  const branchesData = useSelector(state => state.merchant.branchesData);
  const branchData = useSelector(state => state.merchant.branchData);
  const brandsList = useSelector(state => state.product.productBrand);
  const interestRateList = useSelector(state => state.product.productInterestRate);
  const productTypeIndex = useSelector(state => state.product.productTypeIndex);

  // action : 1. 取得所屬分店資訊 2. 取得登入帳號單一商家資訊 3. 取得品牌列表 4. 取得利率表 5. 取得所有商品類型項目列表
  useEffect(() => {
    dispatch(merchantActionCreators.getBranchesData());
    dispatch(merchantActionCreators.getBranchData(merAcctId));
    dispatch(actionCreators.getProductBrand());
    dispatch(actionCreators.getProductInterestRate());
    dispatch(actionCreators.getProductTypeIndex());
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      prodCalculateType: branchData.merCalculateType === 2 ? 2 : 1,
      merAcctId: branchData && branchData.merAcctId ? branchData.merAcctId : null,
    });
    changeBrand(branchData && branchData.merAcctId ? branchData.merAcctId : null);
  }, [ branchData ]);

  // 取得商品資訊[]
  useEffect(() => {
    if (prodNo || prodCloneNo) {
      let no = prodNo || prodCloneNo;

      // 編輯
      productService.getProductData(no)
        .then(
          res => {
            res.json().then(result => {
              form.setFieldsValue({
                ...result,
                prodTypeGroup: `${result.prodTypeNo}-${result.prodItemNo}`,
                // 複製商品時，試算金額清空不帶入值
                prodPayAmt: prodCloneNo ? '' : result.prodPayAmt,
                prodPriceAmt: prodCloneNo ? '' : result.prodPriceAmt,
                prodDownPayment: prodCloneNo ? 0 : result.prodDownPayment
              });
              changeCalculateType(result.prodCalculateType);
              // 處理商品選項
              let rowProdData = result.prodSpecItem.map(prod => {
                return {
                  id: uuidv4(),
                  prodSpecName: prod.prodSpecName,
                  prodInventory: prod.prodInventory,
                  prodSpecNo: prod.prodSpecNo,
                }
              });
              if (rowProdData.length > 0) setProdSpecData(rowProdData);
              setProdDetailDesString(result.prodDetailDes);
              productTypeIndex.map(item => {
                if(item.prodTypeNo === result.prodTypeNo) {
                  setTypeItemOptions(item.PII);
                }
              });
              getProdPhotoData(result.prodPhoto);
              getProdDesPhotoData(result.prodDesPhoto);
              setIsLoading(false);
            })
          },
          error => {
            setIsLoading(false);
          }
        );
    } else {

      let findMinIntNo = 28;

      if(interestRateList.length !== 0) {
        const findIntNo = interestRateList.map(item => item.intNo).sort((a, b) => a-b);
        if(!findIntNo.includes(28)) findMinIntNo = findIntNo[0];
      }

      // 新增
      form.setFieldsValue({
        prodDownPayment: 0, // 預設都是0
        prodMgtStatus: 3, // 預設為閉鎖
        prodIntNo: findMinIntNo, // 預設為一般利率表
      });
      prodSpecData.map(item => {
        form.setFieldsValue(item);
      })
      changeCalculateType(1);
      getItemOptions();
      setIsLoading(false);
    }
}, [form]);

  const getItemOptions = (no) => {
    // 重新設定商品類型子項目選項
    let typeNo = no || form.getFieldValue('prodTypeNo');
    productTypeIndex.map(item => {
      if(item.prodTypeNo === typeNo) {
        setTypeItemOptions(item.PII);
        form.setFieldsValue({prodItemNo: item.PII[0].prodItemNo}); // 預設第一個
        form.setFieldsValue({prodTypeGroup: `${typeNo}-${item.PII[0].prodItemNo}`})
      }
    });
  };

  const addProdSpecItem = () => {
    // 新增商品選項
    setProdSpecData([...prodSpecData, {
      id: uuidv4(),
      prodSpecName: '',
      prodInventory: 1
    }]);
  };

  const deleteProdSpecItem = (id) => {
    // 刪除商品選項
    const data = [...prodSpecData];
    data.splice(data.findIndex(value => value.id === id), 1);
    setProdSpecData(data);
  };

  const handleChangeInput = (e, id) => {
    // 商品選項資料更改
    const data = prodSpecData.map(item => {
      if(id === item.id) {
        item[e.target.name] = e.target.value
      }
      return item;
    });
    setProdSpecData(data);
  };

  const changeBrand = (val) => {
    // 選擇所屬分店，取分店相對應試算方式
    let brand = branchesData.filter(item => item.merAcctId === val); 

    if (brand && brand[0] && brand[0].merCalculateType) {
      setCalcTypeOpts([brand[0].merCalculateType]);
      // 當只有一個選項時，直接預設給值
      form.setFieldsValue({
        prodCalculateType: brand[0].merCalculateType,
      });
      setCalcType(brand[0].merCalculateType);
    } else {
      // 0 or null
      setCalcTypeOpts([1, 2]);
    };
  };

  const getColumns = () => {
    // 分期試算 table column
    return [
      {
        title: '分期期數',
        dataIndex: 'periods',
      },
      {
        title: '分期金',
        dataIndex: 'firstInstallment',
      },
      {
        title: `${ calcType == 1 ? '分期基準價' : '撥款金額'}`,
        dataIndex: `${ calcType == 1 ? 'periodBaseAmt' : 'appropriation'}`,
      },
      {
        title: '分期總價',
        dataIndex: 'periodsTotalAmt',
      },
    ]
  };

  const changeCalculateType = (eVal) => {
    // 分期試算 type
    let typeVal = '';
    if (eVal && eVal.target) typeVal = eVal.target.value;
    else typeVal = eVal;
    setCalcType(typeVal);
  };

  const onEstimation = () => {
    const prodPayAmt = form.getFieldValue('prodPayAmt');
    const prodPriceAmt = form.getFieldValue('prodPriceAmt');
    const prodDownPayment = form.getFieldValue('prodDownPayment');

    if (!form.getFieldValue('prodIntNo')) {
      message.error('請選擇利率專案');
      return false;
    } else if (calcType === 1 && isNaN(parseFloat(prodPayAmt))) {
      message.error('請選擇輸入撥款金額');
      return false;
    } else if (calcType === 2 && (isNaN(parseFloat(prodPriceAmt)) || isNaN(parseFloat(prodDownPayment)))) {
      message.error('請選擇輸入建議售價、頭期款訂金');
      return false;
    } else if (prodPayAmt >= 1000000 || prodPriceAmt >= 1000000 || prodDownPayment >= 1000000) {
      message.error('金額不可超過6位數');
      return false;
    }

    // 分期試算計算
    let payload = {};
    if (calcType === 1) {
      payload = {
        intNo: form.getFieldValue('prodIntNo'),
        calculateType: 'appropriation',
        appropriation: form.getFieldValue('prodPayAmt')
      }
    } else if (calcType === 2) {
      payload = {
        intNo: form.getFieldValue('prodIntNo'),
        calculateType: 'productPrice',
        productPrice: form.getFieldValue('prodPriceAmt'),
        downPayment: form.getFieldValue('prodDownPayment')
      }
    }
    setCalcLoading(true);
    productService.getPrcieEstimation(payload)
      .then(
        res => {
          res.json().then(r => {
            let data = [];
            for (let period in r.result) {
              data.push({...r.result[period], key: period})
            }
            setColumns(getColumns());
            setRowData(data);
          });
          setCalcLoading(false);
        }
      )
  };

  const getProdPhotoData = (data) => {
    // 商品圖片
    let imageData = data.map(item => {
      return {
        prodNo: prodNo,
        docType: 1,
        photoSerNo: item.prodPhotoSerNo,
        name: item.prodPhotoItemNo,
        status: 'done',
        url: item.prodPhotos
      }
    });

    setProdPhotoData(imageData);
  };

  const getProdDesPhotoData = (data) => {
    // 商品描述圖片
    let imageData = data.map(item => {
      return {
        prodNo: prodNo,
        docType: 3,
        photoSerNo: item.prodDesPhotoSerNo,
        name: item.prodDesPhotoItemNo,
        status: 'done',
        url: item.prodDesPhotos
      }
    });
    setProdDesPhotoData(imageData);
  };

  const editorCallBack = (htmlString) => {
    // 編輯框
    setProdDetailDesString(htmlString);
  };

  const uploadProdPhotoCallBack = (imgData) => {
    // 上傳商品圖片
    setProdPhotoData(imgData);
  };

  const uploadProdDesPhotoCallback = (imgData) => {
    // 上傳商品描述圖片
    setProdDesPhotoData(imgData);
  };

  const uploadSingleImage = (prodNo, docType, imgUrl, action) => {
    return productService.addProductImg(prodNo, docType, imgUrl)
      .then(
        res => {
          if (res.ok && res.status !== 200) {
            throw new Error('error');
          }
          return true;
        }
      ).catch(
        error =>  {
          return false;
        }
      )
  };

  const onFinish = async (formData) => {
    setIsLoading(true);
    if (prodSpecData.filter(item => !item.prodSpecName).length > 0) {
      message.error('請填寫商品選項規格');
      setIsLoading(false);
    } else if (formData.prodPayAmt >= 1000000 || formData.prodPriceAmt >= 1000000 || formData.prodDownPayment >= 1000000) {
      message.error('金額不可超過6位數');
      setIsLoading(false);
    } else {
      // 處理商品選項
      let _prodSpecData = prodSpecData.map((item, idx) => {
        return {
          prodInventory: parseInt(item.prodInventory),
          prodSpecName: item.prodSpecName,
          prodSpecNo: idx+1 // 前端重新給值
        };
      });

      let payload = {
        merAcctId: formData.merAcctId,
        prodTypeNo: formData.prodTypeNo,
        prodItemNo: formData.prodItemNo,
        prodMgtStatus: formData.prodMgtStatus,
        prodName: formData.prodName,
        prodBrandNo: formData.prodBrandNo || undefined,
        prodSerialCode: formData.prodSerialCode || undefined,
        prodGift: formData.prodGift || undefined,
        prodDetailDes: prodDetailDesString || undefined,
        prodIntNo: formData.prodIntNo,
        prodCalculateType: formData.prodCalculateType,
        prodSpecItem: !prodNo ? _prodSpecData : undefined, // 新增商品時帶的參數
        prodSpecList: prodNo ? _prodSpecData : undefined, // 編輯商品時帶的參數
      };
      // 處理分期試算參數
      if (calcType === 1) {
        payload = {
          ...payload,
          prodPayAmt: parseInt(formData.prodPayAmt)
        };
      } else {
        payload = {
          ...payload,
          prodPriceAmt: parseInt(formData.prodPriceAmt),
          prodDownPayment: parseInt(formData.prodDownPayment)
        };
      }

      if (prodNo) {
        // 編輯商品
        productService.editProductData(prodNo, payload)
          .then(
            async res => {
              if (res.status === 204) {

                // 編輯後，更新上傳圖片
                if (prodPhotoData.length > 0) {
                  for (let i = 0; i < prodPhotoData.length; i++) {
                    if (!prodPhotoData[i].photoSerNo) {
                      let formData = new FormData();

                      formData.append('doc-image', prodPhotoData[i].originFileObj);

                      const isResData = await uploadSingleImage(prodNo, 1, formData, '編輯');

                      if(!isResData) throw new Error();
                      
                    }
                  }
                }

                if (prodDesPhotoData.length > 0) {
                  for (let i = 0; i < prodDesPhotoData.length; i++) {
                    if (!prodDesPhotoData[i].photoSerNo) {
                      let formData = new FormData();

                      formData.append('doc-image', prodDesPhotoData[i].originFileObj);

                      const isResData = await uploadSingleImage(prodNo, 3, formData, '編輯');

                      if(!isResData) throw new Error();

                    }
                  }
                }
                setIsLoading(false);
                navigate('/admin/product/list', {state: {msg: `編輯成功`, status: 'success'}});
              } else {
                setIsLoading(false);
                notification.warning({
                  message: `編輯失敗`,
                });
              }
            } 
          ).catch(
            error => {
              setIsLoading(false);
              notification.warning({
                message: `編輯失敗`,
              });
            }
          )
      } else {
        // 新增商品、複製商品
        productService.addProductData(payload)
          .then(
            async res => {
              if (res.ok && res.status === 200) {
                res.json().then(async result => {

                  if(prodPhotoData.length > 0) {
                    for (let i = 0; i < prodPhotoData.length; i++) {
                      if (prodPhotoData[i].photoSerNo) {
        
                        await toDataURL(prodPhotoData[i].url, async resUrl => {
                          // url -> base64 -> blob
                          const blobData = convertBase64ToBlob(resUrl)
        
                          let formData = new FormData();
            
                          formData.append('doc-image', blobData);
            
                          const isResData = await uploadSingleImage(result.prodNo, 1, formData, '新增');
            
                          if(!isResData) throw new Error();
                        });
   
                      }else {
                        // 後來前端新增的圖片
                        let formData = new FormData();
            
                        formData.append('doc-image', prodPhotoData[i].originFileObj);
            
                        const isResData = await uploadSingleImage(result.prodNo, 1, formData, '新增');
            
                        if(!isResData) throw new Error();
            
                      }
                    }
                  }

                  if(prodDesPhotoData.length > 0) {
                    for (let i = 0; i < prodDesPhotoData.length; i++) {
                      if (prodDesPhotoData[i].photoSerNo) {
        
                        await toDataURL(prodDesPhotoData[i].url, async resUrl => {
                          const blobData = convertBase64ToBlob(resUrl)
        
                          let formData = new FormData();
            
                          formData.append('doc-image', blobData);
            
                          const isResData = await uploadSingleImage(result.prodNo, 3, formData, '新增');
            
                          if(!isResData) throw new Error();
                        });
   
                      }else {
                        // 後來前端新增的圖片
                        let formData = new FormData();
            
                        formData.append('doc-image', prodDesPhotoData[i].originFileObj);
            
                        const isResData = await uploadSingleImage(result.prodNo, 3, formData, '新增');
            
                        if(!isResData) throw new Error();
            
                      }
                    }
                  }
                  setIsLoading(false);
                  navigate('/admin/product/list', {state: {msg: '新增成功', status: 'success'}});
                });
              } else {
                setIsLoading(false);
                notification.warning({
                  message: `新增失敗`,
                });
              }
            }
          ).catch(
            error => {
              setIsLoading(false);
              notification.warning({
                message: `新增失敗`,
              });
            }
          )
      }
    }
  };

  return (
    <Layout className="merLayout" type="flex" align="left">
      <Row justify="center">
        <Col span={23}>
          <Spin spinning={isLoading} tip="Loading...">
            <div className="title">
              商品管理 {prodNo ? '> 編輯' : '> 新增'}
            </div>

            <Card className="merLayoutCard">

              <Form
                labelCol={{flex: "120px"}}
                labelAlign="left"
                colon={false}
                form={form}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Row>

                  {/* 商品資料 */}
                  <Divider
                    className="subTitle"
                    orientation="left"
                    orientationMargin="0"
                    style={{margin: '5px 20px 15px 0'}}
                  >
                    商品資料
                  </Divider>

                  <Col span={12} xs={24} sm={24} md={12}>
                    <Form.Item
                      label="所屬分店"
                      name="merAcctId"
                      style={{ maxWidth: 400 }}
                      rules={[{ required: true, message: '請選擇所屬分店' }]}
                    >
                      <Select placeholder="請選擇" disabled={prodNo} onChange={changeBrand}>
                        {
                          branchesData.map(branch => {
                            return (
                              <Option key={branch.merAcctId}>
                                {branch.merAcctId}-{branch.merCompName}{branch.merIsBranch ? '' : '(總店)'}
                              </Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={24} sm={24} md={12}>
                    <Form.Item
                      label="商品類型"
                      name="prodTypeGroup"
                      style={{ marginBottom: 0 }}
                      rules={[{ required: true, message: '請選擇商品類型' }]}
                    >
                      <Row>
                        <Col span={12}>
                          <Form.Item name="prodTypeNo">
                            <Select placeholder="請選擇" style={{ maxWidth: 200, paddingRight: 5 }} onChange={getItemOptions}>
                            {
                              productTypeIndex.map(_type => {
                                // 判斷屬於此商家的商品類型才顯示(prodAppTypeNo)
                                if (branchData.merMainProdList &&
                                    branchData.merMainProdList.split(',').indexOf(String(_type.appTypeNo)) > -1) {
                                  return (
                                    <Option key={_type.prodTypeNo} value={parseInt(_type.prodTypeNo)}>
                                      {_type.prodTypeDes}
                                    </Option>
                                  )
                                }
                              })
                            }
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="prodItemNo">
                            <Select placeholder="請選擇" style={{ maxWidth: 200 }}>
                            {
                              typeItemOptions.map(_item => {
                                return (
                                  <Option key={_item.prodItemNo} value={parseInt(_item.prodItemNo)}>
                                    {_item.prodItemDes}
                                  </Option>
                                )
                              })
                            }
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="商品狀態"
                      name="prodMgtStatus"
                      style={{ width: 400 }}
                      rules={[{ required: true, message: '請選擇商品狀態' }]}
                    >
                      <Select placeholder="請選擇">
                        {
                          Object.keys(code.prodMgtStatusList()).map(_code => {
                            return (
                              <Option key={_code} value={parseInt(_code)}>
                                {code.prodMgtStatusList()[_code]}
                              </Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="商品名稱" name="prodName"
                      rules={[
                        { required: true, message: `請輸入商品名稱` },
                        { max: 150, message: `長度需小於150字元`}
                      ]}
                    >
                      <Input placeholder="型號 + 容量，EX：iphone 13 pro max 256G 或 柯基犬(白色/母)" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="商品選項" style={{ marginBottom: 0 }}>
                      {
                        prodSpecData.map((row, index) => {
                          return (
                            <Row key={row.id}>
                              <Col span={10}>
                                <Form.Item label="規格" style={{ marginRight: 20 }} required>
                                  <Input
                                    placeholder="顏色、款式(字數限50字)"
                                    name="prodSpecName" value={row.prodSpecName}
                                    onChange={e => handleChangeInput(e, row.id)}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={10}>
                                <Form.Item label="庫存數量" style={{ marginRight: 20 }} required>
                                  <Input
                                    type="number" value={row.prodInventory}
                                    name="prodInventory"
                                    min={0}
                                    onChange={e => handleChangeInput(e, row.id)}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={2}>
                                {
                                  index === 0
                                  ?
                                    <Button
                                      type="primary"
                                      shape="circle"
                                      icon={<PlusOutlined />}
                                      onClick={addProdSpecItem}
                                    />
                                  :
                                    <Button
                                      type="primary"
                                      danger
                                      shape="circle"
                                      icon={<MinusOutlined />}
                                      onClick={() => deleteProdSpecItem(row.id)}
                                    />
                                }
                              </Col>
                            </Row>
                          )
                        })
                      }
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="品牌" name="prodBrandNo" style={{ marginRight: 20 }}>
                      <Select placeholder="請選擇">
                        {
                          brandsList.map(item => {
                            return (
                              <Option key={item.prodBrandNo} value={parseInt(item.prodBrandNo)}>
                                {item.prodBrandDes}
                              </Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="商品型號" name="prodSerialCode"
                      rules={[
                        { max: 30, message: `長度需小於30字元`}
                      ]}
                    >
                      <Input placeholder="EX：CCAI184G0080T2" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="商品贈品說明" name="prodGift"
                      rules={[
                        { max: 50, message: `長度需小於50字元`}
                      ]}
                    >
                      <Input.TextArea placeholder="二次鋼化玻璃保護貼+四腳強化高透空壓殼" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="商品描述">
                      <TextEditor
                        textString={prodDetailDesString}
                        parentCallback={editorCallBack}
                      />
                    </Form.Item>
                  </Col>




                  {/* 管銷資料 */}
                  <Divider
                    className="subTitle"
                    orientation="left"
                    orientationMargin="0"
                    style={{margin: '5px 20px 5px 0'}}
                  >
                    管銷資料
                  </Divider>

                  <Col span={24}>
                    <Typography>
                      <pre>
                        說明：
                        <ul style={{ listStyleType: 'disc' }}>
                          <li>
                            【平台服務費】：撥款予特約商時即先行扣抵。
                          </li>
                          <li>
                            【建議售價】：商家與客戶約定商品售價款。
                          </li>
                          <li>
                            【頭期款】：商家向客戶預收頭期款或訂金。
                          </li>
                          <li>
                            【分期基準價】：撥款金額加上平台服務費。
                          </li>
                          <li>
                            【撥款金額】：實撥入特約商指定帳戶之金額。
                          </li>
                          <li>
                            【統一發票】：彙整當月平台服務費，經雙方確認無誤，於當月或隔月開立發票給予特約商。
                          </li>
                        </ul>
                      </pre>
                    </Typography>
                  </Col>

                  {/* 利率相關 */}
                  <Col span={24} style={{fontSize: 18, paddingBottom: 5}}>
                    <Text type="warning">利率相關</Text>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="利率專案" name="prodIntNo" rules={[{ required: true, message: '請選擇利率專案' }]} style={{ width: 400 }}>
                      <Select placeholder="請選擇">
                        {
                          interestRateList.map(item => {
                            return (
                              <Option key={item.intNo} value={parseInt(item.intNo)}>
                                {item.intName}
                              </Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="分期試算">
                      { !form.getFieldValue('merAcctId') && <Text type="danger">請選擇所屬分店</Text> }
                      { form.getFieldValue('merAcctId') &&
                        <>
                          <Row>
                            <Form.Item label="試算方式" name="prodCalculateType">
                              <Radio.Group onChange={changeCalculateType}>
                                {calcTypeOpts.indexOf(1) > -1 && <Radio value={1}>撥款金額</Radio>}
                                {calcTypeOpts.indexOf(2) > -1 && <Radio value={2}>建議售價</Radio>}
                              </Radio.Group>
                            </Form.Item>
                          </Row>
                          <Row>
                            {/* 根據撥款金額/建議售價決定顯示label及input */}
                            {
                              calcType === 1 &&
                              <Col span={10} xs={24} sm={24} md={11} style={{ marginRight: 20, maxWidth: 250 }}>
                                <Form.Item label="撥款金額" name="prodPayAmt" rules={[{ required: true, message: '請輸入撥款金額' }]}>
                                  <Input addonAfter="元" />
                                </Form.Item>
                              </Col>
                            }
                            {
                              calcType === 2 &&
                              <>
                                <Col span={10} xs={24} sm={24} md={11} style={{ marginRight: 20, maxWidth: 250 }}>
                                  <Form.Item label="建議售價" name="prodPriceAmt" rules={[{ required: true, message: '請輸入建議售價' }]}>
                                    <Input addonAfter="元" />
                                  </Form.Item>
                                </Col>
                                <Col span={10} xs={24} sm={24} md={11} style={{ marginRight: 20, maxWidth: 250 }}>
                                  <Form.Item label="頭期款訂金" name="prodDownPayment" rules={[{ required: true, message: '請輸入頭期款訂金' }]}>
                                    <Input addonAfter="元" />
                                  </Form.Item>
                                </Col>
                              </>
                            }
                            <Col span={2} xs={24} sm={24} md={2}>
                              <Button
                                type="primary"
                                onClick={onEstimation}
                                disabled={calcLoading}
                                loading={calcLoading}
                              >
                                試算
                              </Button>
                            </Col>
                            <Col span={24}>
                              {
                                rowData.length > 0 &&
                                  <Table
                                    columns={columns}
                                    dataSource={rowData}
                                    pagination={false}
                                    size="small"
                                  />
                              }
                            </Col>
                          </Row>
                        </>
                      }
                    </Form.Item>
                  </Col>

                  {/* 商品圖片 */}
                  <Col span={24}>
                    <Divider
                      className="subTitle"
                      orientation="left"
                      orientationMargin="0"
                      style={{margin: '5px 20px 5px 0'}}
                    >
                      商品圖片
                    </Divider>

                    <Form.Item name="prodPhoto">
                      <UploadImg
                        images={prodPhotoData}
                        parentCallback={uploadProdPhotoCallBack}
                      />
                    </Form.Item>
                  </Col>

                  {/* 商品描述圖片 */}
                  <Col span={24}>
                    <Divider
                      className="subTitle"
                      orientation="left"
                      orientationMargin="0"
                      style={{margin: '5px 20px 5px 0'}}
                    >
                      商品描述圖片
                    </Divider>

                    <Form.Item name="prodDesPhoto">
                      <UploadImg
                        images={prodDesPhotoData}
                        parentCallback={uploadProdDesPhotoCallback}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24} align="center">
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={loading}
                      loading={loading}
                    >
                      儲存
                    </Button>
                  </Col>

                </Row>
              </Form>

            </Card>
          </Spin>

        </Col>
      </Row>
    </Layout>
  )
};

export default ProductInfo;
