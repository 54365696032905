import { API_URL as api_url } from 'src/config';

const reqOptions = {
  get: {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  },
};

// 取得商品列表
const getShopProducts = async (shopRouteName, prodTypeNo, prodItemNo, keyword, page, sortData) => {
  const typeNo = prodTypeNo ? `&prodTypeNo=${prodTypeNo}` : "";
  const itemNo = prodItemNo ? `&prodItemNo=${prodItemNo}` : "";
  const word = keyword ? `&keyword=${keyword}` : "";
  const pg = page ? `&page=${page}` : "";
  const sData = sortData ? `&sort=${sortData}` : "";
  return fetch(
    `${api_url}/shops/${shopRouteName}/products?limit=6${typeNo}${itemNo}${word}${pg}${sData}`,
    reqOptions.get
  );
};

// 取得商品分類列表
const getShopProdTypes = async (shopRouteName) => {
  return fetch(
    `${api_url}/shops/${shopRouteName}/product-types`,
    reqOptions.get
  );
};

// 取得商家訂單
const getFrontShopInfo = async (shopRouteName) => {
  return fetch(
    `${api_url}/shops/${shopRouteName}`,
    reqOptions.get
  );
};

// 取得購物須知
const getShopInfoNotice = async (shopRouteName) => {
  return fetch(
    `${api_url}/shops/${shopRouteName}/notice`,
    reqOptions.get
  );
};

// 檢查商店
const checkShopExist = async (shopRouteName) => {
  return fetch(
    `${api_url}/shops/${shopRouteName}/check`,
    reqOptions.get
  );
};

export const frontJetService = {
  getShopProducts,
  getShopProdTypes,
  getFrontShopInfo,
  getShopInfoNotice,
  checkShopExist,
};
