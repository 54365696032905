import React, { useState } from 'react';
import { Modal } from 'antd';

// css
import "./FrontHome.css"

// component
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";


const FrontHome = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleCancel = () => {
    setIsModalOpen(false);
  }

  return (
    <div style={{ marginBottom: 50 }}>
      <Section1 />
      <Section2 />
      <Section3 />
      <Modal
        wrapClassName="front-home-modal"
        title="「反詐騙」公告"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}>
        <div className="front-home-modal-content">
          <p className="font-weight-600">本公司僅提供分期付款服務之業務，從未與「代辦公司」合作，並嚴格「禁止職員」私下受理代辦申請案件，絶對不會要求「高額代辦費」、「收利息才撥款」、「假買賣真詐財」等不肖手法。</p>
          <p className="font-weight-600">若申辦人或與第三人串謀，刻意使用「詐術」，使本公司誤信，因此取得金錢。您已經觸犯「加重詐欺罪」，本公司會將對您提出訟訴，依照刑法「詐欺罪」、「偽造文書罪」 、「洗錢防制法」，必須承擔相關法律責任。</p>
          <p className="font-weight-600 text-center modal-sub-title">提醒您，請勿上當受騙！</p>
          <p className="font-weight-600 modal-sub-content-text">若您發現以上行為，皆有可能已遭不法份子利用，淪為詐騙犯，請立即撥打警政署反詐騙專線「165」查證，並且向警察局報案。</p>
        </div>
      </Modal>
    </div>
  );
}

export default FrontHome;
