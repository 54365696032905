import React from "react";

// antd
import { Row, Col, Collapse } from "antd";

// component
import FrontDivJcoin from "src/components/Front/Divider/DivJcoin";

const Section4 = () => {
  return (
    <>
      <Row justify="center" style={{ marginTop: 70 }}>
        <Col span={20} id="FAQ">
          <FrontDivJcoin title={"　常見問題　"} />
        </Col>
        <Col span={20} md={20} sm={20} xs={20}>
          <Collapse className="jetdai-faq">

            <Collapse.Panel
              header="Q1. 只要備齊所有文件就一定會核准通過嗎？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line mb-none">
                會依據您的整體條件進行審核，備妥文件可幫助您加分，不保證一定核准。
                </p>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              header="Q2. 我現在送出申請的話，甚麼時候可以拿到款項？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line mb-none">
                申請後審核最快30分鐘會有結果，經確認核准且對保完成後，一個工作天內(不含假日)即會撥款入帳。
                </p>
              </div>
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
}

export default Section4;
